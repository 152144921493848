<template>
  <div class="basePanel">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
@import "@/scss/lib";

.basePanel {
  padding: 24px;
  border-radius: 10px;
  background: $color-white;
}
</style>