<template>
  <div class="profileHeader">
    <div
      class="profileHeader__imageWrapper"
      v-on:click="onFileOpenClick"
    >
      <img
        v-bind:src="`${userImage}?w=160&q=80`"
        class="profileHeader__image"
        v-bind:alt="member.name"
        v-on:error="getProfileImageFallback"
      />

      <div class="profileHeader__iconWrapper">
        <font-awesome-icon
          icon="camera"
          class="profileHeader__icon"
        />

        <input
          type="file"
          accept=".jpg,.jpeg,.png"
          ref="imageInput"
          class="profileHeader__imageSelectorInput"
          v-on:change="onImageSelected"
        />
      </div>
    </div>

    <div class="profileHeader__name">
      {{ member.name }}
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import profileMixin from '@/mixins/profile';
import getToken from '@/utils/getToken';
import parseJWT from '@/utils/parseJWT';

export default {
  mixins: [
    profileMixin,
  ],

  computed: {
    ...mapState('member', ['member']),

    userImage() {
      return this.member.image || this.$getDefaultProfileIcon;
    },
  },

  created() {
    const token = getToken();

    if (token) {
      const userId = parseJWT(getToken()).sub;

      this.$echo
        .private(`user.${userId}`)
        .listen('.profile-picture.uploaded', this.fetchMember);
    }
  },

  methods: {
    ...mapActions({
      fetchMember: 'member/fetch',
      updateProfileImage: 'member/updateProfileImage',
    }),

    onFileOpenClick() {
      this.$refs.imageInput.click();
    },

    onImageSelected(event) {
      const files = event.target.files;

      if (files.length) {
        const image = files[0];

        const formData = new FormData();
        formData.append('file', image);

        this.updateProfileImage(formData);
      }
    },

    getProfileImageFallback(event) {
      event.target.src = this.$getDefaultProfileIcon;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.profileHeader {
  display: flex;
  align-items: center;
}

.profileHeader__imageWrapper {
  position: relative;
  margin: 0 rem(16px) 0 0;
  cursor: pointer;
}

.profileHeader__image {
  display: block;
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.profileHeader__imageSelectorInput {
  display: none;
}

.profileHeader__iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background: $color-grey--medium;
  transition: background 0.35s;

  .profileHeader__imageWrapper:hover & {
    background: $color-grey;
  }
}

.profileHeader__icon {
  font-size: 14px;
  color: $color-white;
}

.profileHeader__name {
  @include heading-3;
}
</style>