<template>
  <div class="profilePage">
    <img
      v-bind:src="require('@/assets/profile/doodle-pink.svg')"
      class="profilePage__doodle profilePage__doodle--pink"
    />

    <img
      v-bind:src="require('@/assets/profile/doodle-white.svg')"
      class="profilePage__doodle profilePage__doodle--white"
    />

    <base-section>
      <base-grid modifiers="maxWidth">
        <base-row>
          <base-column>
            <div class="profilePage__content">
              <page-title-bar
                v-bind:class="{
                  'profilePage__titleBar': true,
                  'profilePage__titleBar--overview': isProfileOverview,
                }"
              >
                <span class="profilePage__titleBarContent--title">
                  Instellingen
                </span>

                <router-link
                  v-bind:to="{ name: 'profile.overview' }"
                  class="profilePage__titleBarContent--backLink"
                >
                  <font-awesome-icon
                    icon="arrow-left"
                    class="profilePage__titleBarContentIcon"
                  />

                  Terug
                </router-link>
              </page-title-bar>

              <profile-header
                v-bind:class="{
                  'profilePage__header': true,
                  'profilePage__header--overview': isProfileOverview,
                }"
              />

              <div class="profilePage__layout">
                <profile-sidebar
                  v-bind:class="{
                    'profilePage__sidebar': true,
                    'profilePage__sidebar--overview': isProfileOverview,
                  }"
                />

                <div class="profilePage__view">
                  <component
                    v-bind:is="viewWrapper.type"
                    v-bind="viewWrapper.props"
                    v-bind:class="{
                      'profilePage__viewWrapper': true,
                      'profilePage__viewWrapper--overview': isProfileOverview,
                      [`profilePage__viewWrapper--${viewWrapper.type}`]:  true,
                    }"
                  >
                    <router-view />
                  </component>
                </div>
              </div>
            </div>
          </base-column>
        </base-row>
      </base-grid>
    </base-section>
  </div>
</template>

<script>
import PageTitleBar from '@/components/PageTitleBar';
import BaseSection from '@/components/grid/BaseSection';
import BaseGrid from '@/components/grid/BaseGrid';
import BaseRow from '@/components/grid/BaseRow';
import BaseColumn from '@/components/grid/BaseColumn';
import BasePanel from '@/components/BasePanel';
import ProfileHeader from '@/components/profile/ProfileHeader';
import ProfileSidebar from '@/components/profile/ProfileSidebar';

export default {
  components: {
    BaseSection,
    BaseGrid,
    BaseRow,
    BaseColumn,
    BasePanel,
    PageTitleBar,
    ProfileHeader,
    ProfileSidebar,
  },

  computed: {
    isProfileOverview() {
      return this.$route.name === 'profile.overview';
    },

    viewWrapper() {
      if (this.isProfileOverview) {
        return {
          type: 'div',
          props: {},
        };
      }

      return {
        type: 'base-panel',
        props: {},
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.profilePage__doodle {
  display: none;
  position: absolute;

  @include desktop {
    display: block;
  }

  &--pink {
    top: 285px;
    left: calc(50% - 700px);
  }

  &--white {
    bottom: 0;
    right: 0;
  }
}

.profilePage__content {
  position: relative;
  z-index: 1;
}

.profilePage__layout {
  @include desktop {
    display: flex;
  }
}

.profilePage__header {
  margin: 30px 0 30px 0;

  @include mobile {
    display: none;
  }

  &--overview {
    @include mobile {
      display: flex;
    }
  }
}

.profilePage__titleBar {
  margin: 0 0 20px 0;
}

.profilePage__titleBarContent {
  &--title {
    display: none;

    .profilePage__titleBar--overview & {
      display: block;
    }
  }

  &--backLink {
    @include heading-4;
    display: flex;
    align-items: center;

    .profilePage__titleBar--overview & {
      display: none;
    }
  }
}

.profilePage__titleBarContentIcon {
  margin: 0 10px 0 0;
  color: $color-beige--dark;
}

.profilePage__sidebar {
  margin: 0 0 rem(32px) 0;

  @include mobile {
    display: none;
  }

  &--overview {
    @include mobile {
      display: block;
    }
  }

  @include desktop {
    flex: 0 0 200px;
    margin: rem(20px) rem(16px) 0 0;
  }
}

.profilePage__view {
  @include desktop {
    flex: 1 1 auto;
  }
}

.profilePage__viewWrapper {
  &--base-panel {
    max-width: 530px;
  }

  &--overview {
    @include mobile {
      display: none;
    }
  }

  &:has(.profileMemberGetMember) {;
    max-width: 780px;
  }
}
</style>
