<template>
  <div class="profileSideBar">
    <div
      v-for="(group, key) in groupedItems"
      v-bind:key="key"
      class="profileSideBar__group"
    >
      <router-link
        v-for="(item, key) in group"
        v-bind:key="key"
        v-bind:to="{ name: item.route }"
        v-bind:class="{
          'profileSideBar__item': true,
          'profileSideBar__item--mobileHidden': item.isMobileHidden,
        }"
      >
        <div class="profileSideBar__iconWrapper">
          <font-awesome-icon
            class="profileSideBar__icon"
            v-bind:icon="item.icon"
          />
        </div>

        <span class="profileSideBar__label">
          {{ item.name }}
        </span>

        <font-awesome-icon
          class="profileSideBar__chevron"
          icon="chevron-right"
        />
      </router-link>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      groupedItems: [
        [
          {
            name: this.$t('navigation.profileOverview'),
            route: 'profile.overview',
            icon: 'house',
            isMobileHidden: true,
          },
          {
            name: this.$t('navigation.profileEdit'),
            route: 'profile.edit',
            icon: 'address-card',
          },
          {
            name: this.$t('navigation.profileContract'),
            route: 'profile.contract',
            icon: 'file-contract',
          },
          {
            name: this.$t('navigation.profileMemberGetMember'),
            route: 'profile.memberGetMember',
            icon: 'user-group',
          },
          {
            name: this.$t('navigation.profileInvoices'),
            route: 'profile.invoices',
            icon: 'file-invoice-dollar',
          },
          {
            name: this.$t('navigation.profilePicture'),
            route: 'profile.picture',
            icon: 'camera',
          },
          {
            name: this.$t('navigation.profilePrivacy'),
            route: 'profile.privacy',
            icon: 'shield-check',
          },
        ],
        [
          {
            name: this.$t('navigation.profileConnections'),
            route: 'profile.connection',
            icon: 'bolt',
          },
          {
            name: this.$t('navigation.profileAccessibility'),
            route: 'profile.accessibility',
            icon: 'text-size',
          },
          {
            name: this.$t('navigation.profilePassword'),
            route: 'profile.password',
            icon: 'lock',
          },
        ],
        [
          {
            name: this.$t('navigation.profileLogout'),
            route: 'logout',
            icon: 'arrow-right-from-bracket',
          },
        ],
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.profileSideBar__group {
  margin: 0 0 rem(10px) 0;
  border-radius: 10px;
  background: $color-white;

  &:last-child {
    margin: 0;
  }

  @include desktop {
    margin: 0 0 rem(20px) 0;
    border-radius: 0;
    background: none;
  }
}

.profileSideBar__item {
  @include small;
  display: flex;
  align-items: center;
  padding: 12px 20px;
  color: $color-text;
  border-bottom: 1px solid $color-beige;

  &--mobileHidden {
    display: none;

    @include desktop {
      display: flex;
    }
  }

  &.router-link-active {
    @include small--bold;
  }

  &:last-child {
    margin: 0;
  }

  @include desktop {
    margin: 0 0 rem(20px) 0;
    padding: 0;
    border: none;
  }
}

.profileSideBar__iconWrapper {
  flex: 0 0 auto;
  margin: 0 rem(10px) 0 0;
  width: 18px;
  text-align: center;
}

.profileSideBar__icon {
  font-size: rem(16px);
  color: $color-beige--dark;

  @include desktop {
    color: $color-grey;

    .router-link-active & {
      color: $color-yellow;
    }
  }
}

.profileSideBar__label {
  flex: 1 1 auto;
}

.profileSideBar__chevron {
  flex: 0 0 auto;
  color: $color-beige--dark;

  @include desktop {
    display: none;
  }
}
</style>
